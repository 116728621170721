// import { TRecoveryPasswordPayload } from 'routes/RecoveryPage/types';
import { Base } from '../../../application/api/Base';
import { config } from '../../..//application/api/config';
import {
  deleteAuthCookies,
  removeHeaders
} from '../../../application/api/headers/headers';
import {
  TApiClientPromise,
  TAuthSignInData
} from '../../../application/api/types';

export class Authorization extends Base {
  signIn(data: TAuthSignInData): TApiClientPromise {
    if (process.env.NODE_ENV === 'development')
      data.isTested = 'bo4k6bN3XHYSKX3S6zlwX2v8L9Vu6X2k08A7e2Uw9Zw';
    return this.apiClient.post(config.authorization.signIn, { ...data });
  }

  // signUp(data: IFormDataSignUp): TApiClientPromise {
  //   if (process.env.NODE_ENV === 'development')
  //     data.isTested = 'bo4k6bN3XHYSKX3S6zlwX2v8L9Vu6X2k08A7e2Uw9Zw'
  //   return this.apiClient.post(config.authorization.signUp, { ...data })
  // }

  signOut(): TApiClientPromise {
    const signOutResponse = this.apiClient.post(config.authorization.signOut);
    deleteAuthCookies();
    removeHeaders();
    return signOutResponse;
  }

  refreshToken(): TApiClientPromise {
    return this.apiClient.put(config.authorization.refreshToken, {});
  }

  forgotPassword(email: string): TApiClientPromise {
    return this.apiClient.get(config.authorization.forgotPassword, { email });
  }
}
export type TAuthResponse = {
  data: {
    token: string;
  };
};
