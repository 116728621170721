import styled from 'styled-components';

export const StyledBreadcrumbs = styled.div({
	'a, span': {
		color: 'rgba(255, 255, 255, .3)',
	},
	"a:hover" : {
		color: 'rgba(255, 255, 255, .8)'
	},
	li: {
		display: 'inline',
	},
	'& .ant-breadcrumb-separator': {
		color: 'rgba(255, 255, 255, .3)'
		
	},
	'& li:last-child': {
		color: 'rgba(255, 255, 255, .3)'
		
	}
})