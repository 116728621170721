export const config = {
  API_ENDPOINT: process.env.API_ENDPOINT,
  // googleRecaptchaSiteKey: '6Lf0xSonAAAAAI0dLVs7Q-URWV6gF29FxADRX2qD',
  authorization: {
    signIn: 'account/login',
    signUp: 'auth/sign-up',
    signOut: 'auth/sign-out',
    refreshToken: 'account/refresh-token',
    forgotPassword: 'account/change-password'
  },
  user: {
    fetchUser: 'account/me'
  },
  categories: {
    fetchCategories: 'categories',
    createCategory: 'categories',
    deleteCategory: 'categories/'
  },
  vacancies: {
    getVacancies: 'vacancies',
    getVacancy: 'vacancies',
    createVacancy: 'vacancies',
    editVacancy: 'vacancies',
    deleteVacancy: 'vacancies',
    updateVacancy: 'vacancies'
  },
  blog: {
    getArticles: 'articles',
    getArticle: 'articles',
    createArticle: 'articles',
    updateArticle: 'articles',
    deleteArticle: 'articles'
  },
  portfolio: {
    getProjects: 'portfolio',
    getProject: 'portfolio',
    createProject: 'portfolio',
    updateProject: 'portfolio',
    deleteProject: 'portfolio'
  }
};
