import { Base } from '../../../application/api/Base';
import { config } from '../../../application/api/config';
import { TApiClientPromise, TArticle } from '../../../application/api/types';

export class Blog extends Base {
  getArticles(): TApiClientPromise {
    return this.apiClient.get(config.blog.getArticles);
  }
	getArticle(id: number): TApiClientPromise {
		return this.apiClient.get(config.blog.getArticle + '/' + id)
	}
  createArticle(data: { data: TArticle }): TApiClientPromise {
    return this.apiClient.post(config.blog.createArticle, data);
  }
	updateArticle(data: TArticle): TApiClientPromise {
		//@ts-ignore
		const {id, dataToSend} = data
		console.log(data);
		
		return this.apiClient.put(config.blog.updateArticle + '/' + id, dataToSend)
	}
	deleteArticle(id: number): TApiClientPromise {
		return this.apiClient.delete(config.blog.deleteArticle + '/' + id)
	}
}
