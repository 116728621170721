import { Divider } from 'antd';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  StyledCheckbox,
  StyledSubmitButton
} from '../../components/AddVacancy/styles';
import CKEditorComponent from '../../components/CKEditor/CKEditor';
import { StyledForm, StyledInput, StyledTitle } from './styles';
import { useAppDispatch } from '../../store';
import { deleteVacancy, getVacancies, updateVacancy } from '../../entities/vacancies/model/slice'
import { useNavigate } from 'react-router-dom'
interface VacancyData {
	id: number,
	name: string,
	is_active: boolean,
	description: string,
	shortDescription: string,
	categoryId: number,
	slug: string,
  keywords: string[]
}

const VacancyForm = ({name, is_active, description, shortDescription, id, categoryId, slug}: VacancyData) => {
	const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [VacancyShortDescription, setVacancyShortDescription] = useState(shortDescription);
  const [VacancyDescription, setVacancyDescription] = useState(description);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: name,
      is_active: is_active
    }
  });
  const submit = (data: any) => {
		const dataToUpdateVacancy = {
			id: id,
			name: data.name,
			is_active: data.is_active,
			description: VacancyDescription,
			short_description: VacancyShortDescription,
			keywords: data.name.split(' '),
			category_id: categoryId,
			slug: slug
		}
		dispatch(updateVacancy(dataToUpdateVacancy))
    navigate(-1)
  };
  const handleDelete = async () => {
    await dispatch(deleteVacancy(id))
    //@ts-ignore
    await dispatch(getVacancies())
    navigate(-1)
  }
  return (
    <>
      <StyledForm onFinish={() => handleSubmit(submit)}>
        <Controller
          name='name'
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <StyledInput
              placeholder={'Title'}
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name='is_active'
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <StyledCheckbox onChange={onChange} defaultChecked={value}>
              Is Active
            </StyledCheckbox>
          )}
        />
        <StyledSubmitButton type='submit'>{'Save'}</StyledSubmitButton>
      </StyledForm>
      <Divider style={{ background: 'rgba(255, 255, 255, .1)' }} />
      <StyledTitle>Short Description</StyledTitle>
      <CKEditorComponent
        value={VacancyShortDescription}
        setContent={setVacancyShortDescription}
      />
      <Divider style={{ background: 'rgba(255, 255, 255, .1)' }} />
      <StyledTitle>Description</StyledTitle>
      <CKEditorComponent value={VacancyDescription} setContent={setVacancyDescription} />
      <StyledSubmitButton style={{margin: '0 0 15px 15px'}} onClick={handleDelete}>Delete</StyledSubmitButton>
    </>
  );
};

export default VacancyForm;
