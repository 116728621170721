import styled from 'styled-components';
import {
  LogInForm,
  StyledLogInForm
} from './components/auth/LogInForm/LogInForm';

export const StyledMainPageLoginForm = styled.div({
  background: '#000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh'
});

export const StyledMainLogInForm = styled(StyledLogInForm)({
  width: '600px',
  borderRadius: '10px',
  overflow: 'hidden',
  div: {
    minWidth: '100%'
  }
});
