import { Spin } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components';

export const StyledPageBlog = styled.div({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	gap: '16px'
})

export const StyledBlogArticle = styled.div({
	width: '30%',
	height: '200px',
  padding: '8px 8px 12px',
  overflow: 'hidden',
  borderRadius: '12px',
  background: 'rgba(0, 0, 0, 0.03)',
  boxShadow: 'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(6, 14, 19, 0.5) 0px 4px 4px 0px, rgba(0, 224, 255, 0.1) 0px 0px 20px 0px',
	transition: 'all .3s',
	'&:hover': {
		transform: 'scale(1.05, 1.05)'
	},
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between'
})
export const StyledImage = styled.img({
	width: '100%',
	height: '100%',
	padding: '15px',
	objectFit: 'contain'
})
export const StyledLink = styled(Link)({
	textAlign: 'center',
	fontSize: '1.5rem',
	width: '100%',
	display: 'block'
})
export const StyledImageBlock = styled(StyledLink)({
	height: '85%'
})

export const StyledSpiner = styled(Spin)({
	width: '100%',
	height: '80%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
})