import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import apiClient from '../../../application/api/';
import { TProject } from '../../../application/api/types'

interface State {
	projects: TProject[] | null,
	project: TProject | null,
	isLoading: boolean,
	ProjectResult: {
		type: string,
		message: string
	}

}

const initialState: State = {
  projects: null,
  project: null,
  isLoading: false,
	ProjectResult: {
		type: '',
		message: ''
	}
};

export const getProjects = createAsyncThunk<AxiosResponse<any, any>>(
  'portfolio/getProjects',
  async (_, { rejectWithValue }) => {
    try {
      const result = await apiClient.portfolio.getProjects();
      //@ts-ignore
      return result.data;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getProject = createAsyncThunk(
  'portfolio/getProject',
  async (id: number, { rejectWithValue }) => {
    try {
      const result = await apiClient.portfolio.getProject(id);
      //@ts-ignore
      return result.data;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);
export const deleteProject = createAsyncThunk('portfolio/deleteProject', async (id: number, {rejectWithValue}) => {
	try {
		await apiClient.portfolio.deleteProject(id)
	}
	catch(e: any) {
		return rejectWithValue(e.message)
	}
})
export const createProject = createAsyncThunk('portfolio/createProject', async (data: TProject, {rejectWithValue}) => {
	try {
		await apiClient.portfolio.createProject(data)
	}
	catch(e: any) {
		return rejectWithValue(e.message)
	}
})
export const updateProject = createAsyncThunk('portfolio/updateProject', async (data: {dataToSend: TProject, id: number}, {rejectWithValue}) => {
	try {
		await apiClient.portfolio.updateProject(data)
	}
	catch(e: any) {
		return rejectWithValue(e.message)
	}
})

export const portfolioSlice = createSlice({
	initialState,
	name: 'portfolio',
	reducers: {

	},
	extraReducers: (builder) =>{
		builder.addCase(getProjects.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(getProjects.fulfilled, (state, action) => {
			state.isLoading = false
			state.projects = action.payload.data
			state.ProjectResult.type = 'success'
			state.ProjectResult.message = 'Projects received success'
		})
		builder.addCase(getProjects.rejected, (state, action) => {
			state.isLoading = false
			state.ProjectResult.type = 'error'
			//@ts-ignore-next-line
			state.result.message = action.payload
		})
		builder.addCase(getProject.pending, state => {
			state.isLoading = true
		})
		builder.addCase(getProject.fulfilled, (state, action) => {
			state.project = action.payload.data
			state.isLoading = false
			state.ProjectResult.type = 'success'
			state.ProjectResult.message = 'Project received success'
		})
	}
})

export const {} = portfolioSlice.actions