import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import apiClient from '../../../application/api';
import { addToast } from '../../../entities/toast/model/slice';
import { RootState } from '../../../store';
import { setIsAuthorized } from '../../../entities/auth/model/slice';

export const fetchUser = createAsyncThunk<UserSliceState, void>(
  'user/fetchUser',
  async (_, { dispatch }) => {
    try {
      const response = await apiClient.user.fetchUser();
      // @ts-ignore:next-line
      return response.data;
    } catch (error) {
      dispatch(addToast({ type: 'error', content: 'Something were wrong' }));
      dispatch(setIsAuthorized(false));
      console.log('error', error);
    }
  }
);

type UserSliceState = {
  id: string;
  nickname: string;
  email: string;
  balanceReal: number;
  balanceBonus: number;
  activeBonus: number;
  lp: number;
  level: number;
  balanceType: 'real' | 'bonus';

  locale: {
    id: number;
    code: string;
  };
  currency: {
    id: number;
    code: string;
  };
  country: {
    id: number;
    code: string;
  };
};

const initialState: UserSliceState = {
  id: '',
  nickname: '',
  email: 'emailexample@gmail.com',
  balanceReal: 0,
  balanceBonus: 0,
  activeBonus: 0,
  lp: 0,
  level: 0,
  balanceType: 'real',
  locale: {
    id: 38,
    code: 'EN'
  },
  currency: {
    id: 1,
    code: 'USD'
  },
  country: {
    id: 230,
    code: 'US'
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUser.fulfilled, (_, action) => {
      return action.payload;
    });
  }
});

export const selectEmail = (state: RootState) => state[userSlice.name].email;
export const selectUser = (state: RootState) => state[userSlice.name];
export const selectBalance = (state: RootState) => {
  return {
    balanceReal: state[userSlice.name].balanceReal,
    balanceBonus: state[userSlice.name].balanceBonus,
    balanceType: state[userSlice.name].balanceType,
    level: state[userSlice.name].level,
    lp: state[userSlice.name].lp
  };
};
export const { clearUser } = userSlice.actions;
