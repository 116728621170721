import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store';
import { useParams } from 'react-router-dom';
import { getProject } from '../../../entities/portfolio/nodel/slice'

const ProjectPage = () => {
	const {id} = useParams()
	const dispatch = useAppDispatch()

	useEffect(() => {
			dispatch(getProject(+id!))
	},[id, dispatch])
	return ( <>hello</> );
}
 
export default ProjectPage;