import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store';
import {
  ProfessionWrapper,
  StyledShortDescription,
  StyledVacancyTitle,
  VacanciesWrapper,
  VacancyCard
} from './styles';
import AddVacancy from '../../components/AddVacancy/AddVacancy';
import { StyledAddButton, StyledListItem } from '../Profassions/styles';
import { useDispatch } from 'react-redux';
import { VacanciesState, getVacancies } from '../../entities/vacancies/model/slice';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom'

const Profession = () => {
  const { vacancies, isLoading } = useAppSelector(state => state.vacancies);
  const { currentProfession } = useAppSelector(state => state.professions);
  const [vacanciesToShow, setVacanciesToShow] = useState<VacanciesState[] | undefined>([])
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    //@ts-ignore
    dispatch(getVacancies());
  }, [currentProfession])
  const currentVacancies = vacancies?.filter(
    item => item.category_id === currentProfession?.id
  );
  return (
    <ProfessionWrapper>
      <StyledAddButton onClick={() => setShow(true)}>add new</StyledAddButton>
      <VacanciesWrapper>
        {currentVacancies?.map(item => (
          <StyledListItem key={uuidv4()}>
            <StyledVacancyTitle>
              <Link to={`${item.id}`}>
            {item.name}
            </Link>
            </StyledVacancyTitle>
          </StyledListItem>
        ))}
      </VacanciesWrapper>
      {show && (
        <AddVacancy
          isCurrentProfesion={true}
          professionsToAdd={currentProfession}
          currentProfession={currentProfession?.name}
          setShow={setShow}
          show={show}
        />
      )}
    </ProfessionWrapper>
  );
};

export default Profession;
