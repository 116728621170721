import { Menu } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const StyledHeader = styled.header({
  margin: '0 0 80px 0'
});

export const CustomMenu = styled(Menu)({
  alignItems: 'center',
  justifyContent: 'center',
  background: '#060606',
  color: '#fff'
});
