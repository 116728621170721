import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import Cookies from 'js-cookie';
import {
  authHeadersKey,
  deleteAuthCookies,
  deleteAuthHeaders,
  setAuthCookies,
  updateAuthHeaders,
  updateHeaders
} from '../headers/headers';
import apiClient from '../index';

import { backend } from '../../../functions';
import { TApiClientPromise } from '../types';

export class ApiClient {
  private readonly prefix: string;

  constructor({ prefix = `${backend}/api/` } = {}) {
    this.prefix = prefix + 'admin/';
    // this.prefix = prefix
    updateAuthHeaders();
  }

  get(
    requestUrl: string,
    params = {},
    responseType: ResponseType = 'json'
  ): TApiClientPromise {
    return request({
      requestUrl,
      method: 'get',
      params,
      prefix: this.prefix,
      responseType
    });
  }

  put(requestUrl: string, payload = {}): TApiClientPromise {
    return request({
      requestUrl,
      method: 'put',
      data: payload,
      prefix: this.prefix
    });
  }

  post(requestUrl: string, payload = {}): TApiClientPromise {
    return request({
      requestUrl,
      method: 'post',
      data: payload,
      prefix: this.prefix
    });
  }

  delete(requestUrl: string, params = {}): TApiClientPromise {
    return request({
      requestUrl,
      method: 'delete',
      params,
      prefix: this.prefix
    });
  }
}

export async function request({
  requestUrl,
  method,
  data = {},
  params = {},
  cancelToken,
  prefix,
  responseType = 'json'
}: AxiosRequestConfig & { prefix: string; requestUrl: string }) {
  const url = `${prefix}${requestUrl}`;

  return requestAxios({
    url,
    method,
    data,
    params,
    cancelToken,
    responseType
  });
}

export async function requestAxios({
  url,
  method,
  data,
  params = {},
  cancelToken,
  responseType
}: AxiosRequestConfig): Promise<TApiClientPromise> {
  updateAuthHeaders();

  try {
    const response = await axios({
      method,
      url,
      params,
      data,
      cancelToken,
      responseType
    });

    if (response.status >= 200 && response.status < 300) {
      if (response.data) {
        if (response.data.token) {
          setAuthCookies(response.data.token);
        }
        response.data = response.data;
      }
    }

    return response;
  } catch (xhr) {
    // @ts-ignore:next-line

    if (xhr?.response?.status === 401) {
      // to avoid loop when token invalid
      const accessToken = Cookies.get(authHeadersKey);
      if (accessToken) {
        deleteAuthCookies();
        // @ts-ignore:next-line
        const refreshResponse: AxiosResponse =
          await apiClient.authorizations.refreshToken(); // try to refresh token
        if (refreshResponse.data?.token) {
          updateHeaders(refreshResponse.data);

          return requestAxios({
            url,
            method,
            data,
            params,
            cancelToken,
            responseType
          });
        } else {
          deleteAuthCookies();
          deleteAuthHeaders();
          throw new Error('error');
        }
      }
    } else {
      // @ts-ignore:next-line
      if (
        xhr &&
        // @ts-ignore:next-line
        xhr.response &&
        // @ts-ignore:next-line
        xhr.response.data &&
        // @ts-ignore:next-line
        xhr.response.data.message &&
        // @ts-ignore:next-line
        Array.isArray(xhr.response.data.message)
      ) {
        // @ts-ignore:next-line
        xhr.response.data.message.forEach(error => {
          throw new Error(error.message);
        });
      } else {
        // deleteAuthCookies();
        // deleteAuthHeaders();
        //@ts-ignore
        throw new Error(xhr.response.data.message);
      }
    }

    throw new Error('Something went wrong');
  }
}
