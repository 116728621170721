import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const ProfessionsWrapper = styled.div({
  maxWidth: '1920px',
  padding: '0 15px',
  width: '100%'
});

export const StyledListWrapper = styled.ul({
  padding: '0 0 0 25px',
  maxWidth: '80vw',
  width: '100%',
  margin: '0 auto'
});

export const StyledListItem = styled.li({
  color: '#fff',
  fontSize: '1.4rem',
  padding: '15px 0 15px 0',
  position: 'relative',
  margin: '0 0 15px 0',
  display: 'flex',
  justifyContent: 'space-between',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    left: '0',
    display: 'inline-block',
    height: '2px',
    width: '100%',
    background: '#fff'
  },
  '&:before': {
    position: 'absolute',
    content: '""',
    width: '0',
    height: '2px',
    left: '0',
    bottom: '0',
    zIndex: '1',
    transition: 'all .3s ease-in-out',
    background:
      'linear-gradient(90deg, rgba(0,27,36,0.7391748935902486) 0%, rgba(64,176,255,1) 25%, rgba(35,134,255,1) 50%, rgba(7,23,255,1) 75%, rgba(22,0,255,1) 100%)'
  },
  '&:hover': {
    cursor: 'pointer'
  },
  '&:hover:before': {
    width: '100%'
  }
});
export const StyledCloseButton = styled(CloseOutlined)({
  'svg:hover': {
    fill: '#606060'
  },
  zIndex: '10'
});

export const StyledAddButton = styled.button({
  color: '#fff',
  margin: '0 0 15px auto',
  display: 'block',
  padding: '10px 20px',
  border: '1px solid #fff',
  borderRadius: '10px',
  transition: 'all .3s',
  '&:hover': {
    transform: 'scale(1.1)'
  }
});
