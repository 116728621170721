import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import ArticlePage from './pages/Blog/ArticlePage/ArticlePage';
import BlogPage from './pages/Blog/BlogPage';
import Main from './pages/Main/Main';
import PortfolioPage from './pages/Portfolio/PortfolioPage';
import Professions from './pages/Profassions/Professions';
import Profession from './pages/Profession/Profession';
import Vacancy from './pages/Vacancy/Vacancy';
import ProjectPage from './pages/Portfolio/ProjectPage/ProjectPage'

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <Main />
      },
      {
        path: '/proffesions',
        element: <Professions />
      },
      {
        path: '/proffesions/:profession',
        element: <Profession />
      },
      {
        path: '/proffesions/:profession/:id',
        element: <Vacancy />
      },
      {
        path: '/portfolio',
        element: <PortfolioPage />
      },
      {
        path: '/blog',
        element: <BlogPage />
      },
      {
        path: '/blog/:id',
        element: <ArticlePage />
      },
      {
        path: '/portfolio/:id',
        element: <ProjectPage />
      }
    ]
  }
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
