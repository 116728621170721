import Sidebar from './components/SideBar/SideBar';
import Toast from './components/Toast/Toast'

const Layout = () => {
  return (
    <>
      <Sidebar />
      <Toast/>
    </>
  );
};

export default Layout;
