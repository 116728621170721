import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiClient from '../../../application/api/'
import { TVacancies } from '../../../application/api/types'

export type VacanciesState = {
  id?: number;
  category_id: number;
  name: string;
  short_description: string;
  keywords: string[];
  slug: string;
  is_active: boolean;
  description: string;
};
type Data = {
  data: VacanciesState[];
};
type Result = {
  message: string,
  type: string,
}
const initialState: {
  vacancies: VacanciesState[] | null;
  vacancy: VacanciesState | null;
  isLoading: boolean;
  result: Result
} = {
  vacancies: null,
  vacancy: null,
  isLoading: false,
  result: {
    type: '',
    message: '',
  }
};

export const createVacancy = createAsyncThunk(
  'vacancies/createVacancy',
  async (data: TVacancies, { rejectWithValue }) => {
    try {
      await apiClient.vacancies.createVacancy(data);
    } 
    catch (e: any) {
    return  rejectWithValue(e.message);
  }
  }
);

export const getVacancies = createAsyncThunk<Data, any>(
  'vacancies/getVacancies',
  async () => {
    try {
      const result = await apiClient.vacancies.getVacancies();
      //@ts-ignore
      return result.data;
    } catch (e) {
      console.log(e);
    }
  }
);
export const getVacancy = createAsyncThunk<VacanciesState, any>(
  'vacancy/getVacancy',
  async (id: number) => {
    try {
      const result = await apiClient.vacancies.getVacancy(id);
      //@ts-ignore
      return result.data;
    } catch (e) {
      console.log(e);
    }
  }
);
export const updateVacancy = createAsyncThunk<VacanciesState, any>(
  'vacancy/updateVacancy',
  //@ts-ignore
  async (dataToSend: TVacancies, {rejectWithValue}) => {
    try {
      await apiClient.vacancies.updateVacancy(dataToSend);
    } catch (e: any) {
      return rejectWithValue(e.message)
    }
  }
);
export const deleteVacancy = createAsyncThunk('vacancy,deleteVacancy',
async (id:number) => {
  try {
    await apiClient.vacancies.deleteVacancies(id)
  }
  catch (e) {
    console.log(e);
    
  }
})
export const vacanciesSlice = createSlice({
  initialState,
  name: 'vacancies',
  reducers: {
    deleteResult: (state) => {
     state.result.message = ''
     state.result.type = ''
    },
    clear: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(getVacancies.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getVacancies.fulfilled, (state, action) => {
      state.vacancies = action.payload.data;
      state.isLoading = false;
      state.result.type = 'success'
      state.result.message = 'Vacancies received'
    });
    builder.addCase(getVacancy.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getVacancy.fulfilled, (state, action) => {
      //@ts-ignore
      state.vacancy = action.payload.data;
      state.isLoading = false;
      state.result.type = 'success'
      state.result.message = 'Vacancy received'
    });
    builder.addCase(createVacancy.rejected, (state,action) => {
      state.result.type = 'error'
      //@ts-ignore
      state.result.message = action.payload
    })
    builder.addCase(createVacancy.fulfilled, (state, action) => {
      state.result.type = 'success'
      state.result.message = 'Vacancy created'
    })
    builder.addCase(deleteVacancy.fulfilled, (state) => {
      state.result.type = 'success'
      state.result.message = 'Vacancy deleted'
    })
  }
});

export const { clear, deleteResult } = vacanciesSlice.actions;
