import {
  FileTextOutlined,
  FundProjectionScreenOutlined,
  LogoutOutlined,
  MessageOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  getProfessions,
  setCurrentProffesion
} from '../../entities/professions/model/slice';
import { useAppDispatch, useAppSelector } from '../../store';
import Breadcrumbs from '../BreadCrumbs/Breadcrumbs';
import HeaderComponent from '../Header/Header';
import { StyledLayout } from './styles';
import { deleteAuthCookies, deleteAuthHeaders } from '../../application/api/headers/headers'
import { signOutUser } from '../../entities/auth/model/slice'
import { getArticles } from '../../entities/blog/model/slice'
const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { professions } = useAppSelector(state => state.professions);
  const curProfItem = (profName: string) => {
    const curProf = professions?.filter(item => item.name === profName);
    const result = curProf && curProf[0];
    return result;
  };
  useEffect(() => {
    dispatch(getProfessions());
  }, []);
  const menuItems = professions?.map(item =>
    getItem(item.name, `proffesions/${item.slug}`)
  );
  const items: MenuItem[] = [
    getItem('Professions', 'sub1', <FundProjectionScreenOutlined />, menuItems),
    getItem('Portfolio', 'portfolio', <FileTextOutlined />),
    getItem('Blog', 'blog', <MessageOutlined />),
    getItem('LogOut', 'logout', <LogoutOutlined />)
  ];
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
      >
        <div className='demo-logo-vertical' />
        <Menu
          theme='dark'
          defaultSelectedKeys={['1']}
          mode='inline'
          items={items}
          onClick={e => {
            //@ts-ignore
            if (e.domEvent.target.innerText === 'LogOut') {
              dispatch(signOutUser())
            }
            else {
              dispatch(
                //@ts-ignore
                setCurrentProffesion(curProfItem(e.domEvent.target.innerText))
              );
              navigate(`${e.key}`);
            }}
            }
        />
      </Sider>
      <StyledLayout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <HeaderComponent />
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumbs />
          </Breadcrumb>
          <Outlet />
        </Content>
      </StyledLayout>
    </Layout>
  );
};

export default Sidebar;
