import { combineReducers } from '@reduxjs/toolkit';
import { userSlice } from '../entities/user/model/slice';
import { toastSlice } from '../entities/toast/model/slice';
import { authSlice } from '../entities/auth/model/slice';
import { professionsSlice } from '../entities/professions/model/slice';
import { vacanciesSlice } from '../entities/vacancies/model/slice';
import { blogSlice } from '../entities/blog/model/slice'
import { portfolioSlice } from '../entities/portfolio/nodel/slice'

// all slices should be connected here
export const rootReducer = combineReducers({
  // to not specify when name is modified
  [userSlice.name]: userSlice.reducer,
  [toastSlice.name]: toastSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [professionsSlice.name]: professionsSlice.reducer,
  [vacanciesSlice.name]: vacanciesSlice.reducer,
  [blogSlice.name]: blogSlice.reducer,
  [portfolioSlice.name]: portfolioSlice.reducer
});
