import { Input } from 'antd';
import styled from 'styled-components';

export const StyledAddCategoryWrapper = styled.div({
  position: 'absolute',
  padding: '15px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  height: 'fit-content',
  backdropFilter: 'blur(3px)',
  background: 'rgba(0, 0, 0, .3)',
  borderRadius: '10px',
  label: {
    color: '#fff !important'
  },
  zIndex: 999
});
export const StyledInput = styled(Input)({
  width: '50%',
  margin: '0 auto'
});
