import { Base } from '../../../application/api/Base';
import { config } from '../../../application/api/config';
import { TApiClientPromise, TVacancies } from '../../../application/api/types';

export class Vacancies extends Base {
  getVacancies(): TApiClientPromise {
    return this.apiClient.get(config.vacancies.getVacancies);
  }
  getVacancy(id: number): TApiClientPromise {
    return this.apiClient.get(config.vacancies.getVacancy + '/' + id)
  }
  deleteVacancies(id: number): TApiClientPromise {
    return this.apiClient.delete(config.vacancies.deleteVacancy + '/' + id);
  }
  editVacancy(id: { id: number }, data: TVacancies) {
    return this.apiClient.put(config.vacancies.editVacancy + '/' + id, data);
  }
  createVacancy(data: TVacancies) {
    return this.apiClient.post(config.vacancies.createVacancy, data);
  }
  updateVacancy(dataToSend:  TVacancies) {
    const {id} = dataToSend
    
    return this.apiClient.put(config.vacancies.updateVacancy + '/' + id, dataToSend)
  }
}
