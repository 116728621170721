import { Form as AntForm, Input, Select } from 'antd';
import styled from 'styled-components';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { createVacancy, getVacancies} from '../../entities/vacancies/model/slice';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Dispatch, SetStateAction, useState } from 'react';
import CKEditorComponent from '../CKEditor/CKEditor';
import {
  STyledInputKeywords,
  StyledCheckbox,
  StyledCloseButton,
  StyledFirstLine,
  StyledFirstLineRight,
  StyledFirstLineWrap,
  StyledInput,
  StyledPopupWrapper,
  StyledSubmitButton,
  StyledTitle
} from './styles';
import { currentProfession } from '../../entities/professions/model/slice';
import { navPath } from '../../functions';
import { useNavigate } from 'react-router-dom'

type VacancyProps = {
  professionsToAdd: any;
  isCurrentProfesion: boolean;
  currentProfession?: string | null | currentProfession[];
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
};
const AddVacancy = ({
  professionsToAdd,
  isCurrentProfesion,
  currentProfession,
  setShow,
  show
}: VacancyProps) => {
  const [description, setDescription] = useState<string>('');
  const [shortDescription, setShortDescription] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const { vacancies } = useAppSelector(state => state.vacancies);
  const { professions } = useAppSelector(state => state.professions);
  const [keywords, setKeyWords] = useState<string[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const newArray = vacancies && [...vacancies];
  const handleFinish = (data: { title: string; profession: string }) => {
    dispatch(
      //@ts-ignore
      createVacancy({
        category_id: !isCurrentProfesion
          ? parseInt(data.profession)
          : professionsToAdd.id,
        name: data.title,
        short_description: shortDescription,
        keywords: keywords,
        slug: navPath(data.title),
        is_active: isActive,
        description: description
      })
    );
    //@ts-ignore
    navigate('/proffesions')
    setShow(false);
  };
  
  return (
    <StyledPopupWrapper $show={show}>
      <StyledCloseButton onClick={() => setShow(false)}>X</StyledCloseButton>
      <AntForm
        name='normal_login'
        className='login-form'
        layout='vertical'
        requiredMark={false}
        initialValues={{
          title: ''
        }}
        onFinish={handleFinish}
      >
        <AntForm.Item className='checkbox'>
          <StyledCheckbox onChange={e => setIsActive(e.target.checked)}>
            Is Active
          </StyledCheckbox>
          ;
        </AntForm.Item>
        <StyledFirstLineWrap>
          <StyledFirstLine>
            <AntForm.Item label={'Title'} name='title' hasFeedback>
              <Input placeholder={'Title'} />
            </AntForm.Item>
            <AntForm.Item label={'Profession'} name='profession' hasFeedback>
              {!isCurrentProfesion ? (
                <Select
                  style={{ width: '250px' }}
                  defaultValue={'Chose Profession'}
                  options={professions?.map(item => ({
                    value: item.id,
                    label: item.name
                  }))}
                />
              ) : (
                <StyledInput
                  disabled={true}
                  defaultValue={professionsToAdd.name}
                />
              )}
            </AntForm.Item>
          </StyledFirstLine>
          <StyledFirstLineRight>
            <StyledSubmitButton type='submit'>{'Save'}</StyledSubmitButton>
          </StyledFirstLineRight>
        </StyledFirstLineWrap>
        <StyledTitle>Keywords</StyledTitle>
        <STyledInputKeywords onChange={(e) => setKeyWords(e.target.value.split(','))}/>
      </AntForm>
      <StyledTitle>Description</StyledTitle>
      <CKEditorComponent value={description} setContent={setDescription} />
      <StyledTitle>Short Description</StyledTitle>
      <CKEditorComponent
        value={shortDescription}
        setContent={setShortDescription}
      />
    </StyledPopupWrapper>
  );
};

export default AddVacancy;
