import { Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditPost from '../../../components/EditPost/EditPost';
import { clearArticle, getArticle } from '../../../entities/blog/model/slice';
import { backend } from '../../../functions';
import { useAppDispatch, useAppSelector } from '../../../store';
const ArticlePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { article } = useAppSelector(state => state.blog);

  const handleCancel = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (id) dispatch(getArticle(+id));
    return () => {
      dispatch(clearArticle());
    };
  }, [id, dispatch]);
  return article ? (
    <EditPost
      cancel={handleCancel}
      title={article!.title}
      postShortDescription={article!.short_description}
      postDescription={article!.content}
      id={id!}
      slug={article!.slug}
      imageUrl={`${backend}${article!.image}`}
      keywords={article!.keywords}
    />
  ) : (
    <Spin size='large' />
  );
};

export default ArticlePage;
