import { Authorization } from '../../entities/auth/api/Authorization';
import { ApiClient } from './ApiClient/ApiClient';
import { IApi } from './types';
import { config } from './config';
import { User } from '../../entities/user/api/User';
import { Professions } from '../../entities/professions/api/Professions';
import { Vacancies } from '../../entities/vacancies/api/Vacancies';
import { Blog } from '../../entities/blog/api/Blog'
import { Portfolio } from '../../entities/portfolio/api/Portfolio'
export function getApiClient({
  apiPrefix = process.env.API_ENDPOINT
}: {
  apiPrefix?: string;
}): IApi {
  const api = new ApiClient({ prefix: apiPrefix });
  return {
    authorizations: new Authorization({ apiClient: api }),
    user: new User({ apiClient: api }),
    professions: new Professions({ apiClient: api }),
    vacancies: new Vacancies({ apiClient: api }),
    blog: new Blog({apiClient: api}),
    portfolio: new Portfolio({apiClient: api})
  };
}
export default getApiClient({
  // @ts-ignore:next-line
  apiPrefix: config.API_ENDPOINT
});
