import { Base } from '../../../application/api/Base';
import { config } from '../../../application/api/config';
import { TApiClientPromise, TProject } from '../../../application/api/types';

export class Portfolio extends Base {
  getProjects(): TApiClientPromise {
    return this.apiClient.get(config.portfolio.getProjects);
  }
  getProject(id: number): TApiClientPromise {
    return this.apiClient.get(config.portfolio.getProject + '/' + id);
  }
  deleteProject(id: number): TApiClientPromise {
    return this.apiClient.delete(config.portfolio.deleteProject + '/' + id);
  }
  createProject(data: TProject): TApiClientPromise {
    return this.apiClient.post(config.portfolio.createProject, data);
  }
  updateProject(data: { id: number; dataToSend: TProject }): TApiClientPromise {
    const { id, dataToSend } = data;
    return this.apiClient.put(
      config.portfolio.updateProject + '/' + id,
      dataToSend
    );
  }
}
