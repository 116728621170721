import { Base } from '../../../application/api/Base';
import { config } from '../../../application/api/config';
import { TApiClientPromise } from '../../../application/api/types';

export class Professions extends Base {
  getProfessions(): TApiClientPromise {
    return this.apiClient.get(config.categories.fetchCategories);
  }
  createProfession(data: { data: any }): TApiClientPromise {

    return this.apiClient.post(config.categories.createCategory, data);
  }
}
