export const navPath = (str: string) => {
  return str.toLocaleLowerCase().split(' ').join('-').split('/').join('-');
};
export function bufferToText(buffer: Buffer): string {
  return buffer.toString('utf-8');
}

export const backend = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT
  : 'https://send-dev.skillwebquality.com';
