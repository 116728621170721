import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { CustomMenu, StyledHeader } from './styles';
import { useAppSelector } from '../../store';
const HeaderComponent = () => {
  // const { professions } = useAppSelector(state => state.professions);

  const items: MenuProps['items'] = [
    {
      label: <Link to={'/proffesions'}>Proffesions</Link>,
      key: 'SubMenu'
      // children: professions.map(item => {
      //   return {
      //     label: item,
      //     key: item
      //   };
      // })
    }
  ];
  return (
    <StyledHeader>
      <CustomMenu mode='horizontal' items={items}></CustomMenu>
    </StyledHeader>
  );
};

export default HeaderComponent;
