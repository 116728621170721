import { Checkbox, Input } from 'antd';
import styled from 'styled-components';

export const flexBox = `
display: flex;
align-items: center;
`;
export const StyledFirstLine = styled.div`
  ${flexBox};
  gap: 24px;
  max-width: 550px;
  label {
    color: #fff !important;
  }
`;
export const StyledFirstLineRight = styled.div`
  ${flexBox};
  gap: 24px;
  align-items: flex-start;
`;
export const StyledFirstLineWrap = styled.div`
  ${flexBox};
  justify-content: space-between;
  padding: 0 15px;
`;
export const StyledInput = styled(Input)({
  background: '#fff',
  '&:hover': {
    background: '#fff'
  }
});
export const StyledSubmitButton = styled.button({
  background: 'transparent',
  color: '#fff',
  borderRadius: '10px',
  border: '1px solid #fff',
  padding: '15px 20px',
  transition: 'all .5s',
  '&:hover': {
    transform: 'scale(1.1, 1.1)'
  }
});
export const StyledPopupWrapper = styled.div<{ $show?: boolean }>(
  ({ $show }) => ({
    position: 'absolute',
    width: '80vw',
    height: '82vh',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%)`,
    transition: 'all .5s',
    zIndex: 999,
    background: '#000',
    borderRadius: '8px',
    boxShadow:
      '6px 6px 7px rgb(255 255 255 / 20%), inset 1px 1px 9px rgb(139 139 139)',
    overflow: 'auto',
    '.checkbox': {
      margin: '0'
    }
  })
);
export const StyledTitle = styled.h2({
  padding: '0 15px',
  color: '#fff',
  margin: '0 0 15px 0'
});
export const StyledCloseButton = styled.button({
  background: 'transparent',
  color: '#fff',
  borderRadius: '10px',
  border: '1px solid #fff',
  padding: '5px 10px',
  display: 'block',
  margin: '10px 10px 0 auto'
});

export const StyledCheckbox = styled(Checkbox)({
  margin: '0 0 0 auto',
  display: 'flex',
  justifyContent: 'flex-end',
  color: '#fff'
});
export const STyledInputKeywords = styled(StyledInput)({
  maxWidth: '50%',
  margin: '0 0 20px 15px'
})