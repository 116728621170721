import { Form, Input} from 'antd';
import styled from 'styled-components'

export const StyledForm = styled(Form)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '0 15px',
	gap: '15px'
})

export const StyledInput = styled(Input)({
	maxWidth: '30%'
})
export const StyledTitle = styled.h2({
	margin: '0 0 15px 0'
})