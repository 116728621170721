import { Form as AntForm, Input } from 'antd';
import { StyledAddCategoryWrapper, StyledInput } from './styles';
import { StyledCloseButton, StyledSubmitButton } from '../AddVacancy/styles';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { navPath } from '../../functions';
import {
  createProfession,
  getProfessions
} from '../../entities/professions/model/slice';
type Category = {
  category: string;
};
type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
};
const AddCategory = ({ setShow }: Props) => {
  const dispatch = useDispatch();
  const onFinish = (data: Category) => {
    const dataToSend = {
      name: data.category,
      slug: navPath(data.category)
    };
    dispatch(
      //@ts-ignore
      createProfession(dataToSend)
    );
    //@ts-ignore
    dispatch(getProfessions());
  };
  return (
    <StyledAddCategoryWrapper>
      <StyledCloseButton onClick={() => setShow(false)}>X</StyledCloseButton>
      <AntForm
        name='normal_login'
        className='login-form'
        layout='vertical'
        requiredMark={false}
        initialValues={{ remember: false }}
        onFinish={onFinish}
      >
        <AntForm.Item label={'Category Name'} name='category' hasFeedback>
          <StyledInput placeholder='Category' />
        </AntForm.Item>
        <StyledSubmitButton style={{ marginLeft: 'auto' }} type='submit'>
          Save
        </StyledSubmitButton>
      </AntForm>
    </StyledAddCategoryWrapper>
  );
};

export default AddCategory;
