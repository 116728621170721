import { useEffect } from 'react';
import { getProjects } from '../../entities/portfolio/nodel/slice';
import { backend } from '../../functions';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  STyledProjectImage,
  StyledImage,
  StyledLink,
  StyledPagePortfolio,
  StyledProjectsWrapper
} from './styled';
const PortfolioPage = () => {
  const dispatch = useAppDispatch();
  const { projects } = useAppSelector(state => state.portfolio);
  useEffect(() => {
    dispatch(getProjects());
  }, []);
  if (!projects) {
    return <h1>Loading...</h1>;
  }
  return (
    <StyledPagePortfolio>
      {projects.map(project => (
        <StyledProjectsWrapper key={project.id}>
          <STyledProjectImage>
            <StyledLink to={`${project.id}`}>
              <StyledImage src={`${backend}${project.image}`} alt='' />
            </StyledLink>
          </STyledProjectImage>
          <div className='title'>
            <StyledLink to={`${project.id}`}>{project.title}</StyledLink>
          </div>
          <div className='shortDescription'>{project.short_description}</div>
        </StyledProjectsWrapper>
      ))}
    </StyledPagePortfolio>
  );
};

export default PortfolioPage;
