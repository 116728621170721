import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../../application/api/';
import { TArticle } from '../../../application/api/types'
import { AxiosResponse } from 'axios'

interface State {
	articles: TArticle[] | null,
	article: TArticle | null,
	blogResult: {
		type: string,
		message: string
	},
	isLoading: boolean
}

const initialState:State = {
	articles: null,
	article: null,
	blogResult: {
		type: '',
		message: ''
	},
	isLoading: true
}

export const getArticles = createAsyncThunk<AxiosResponse<any, any>>('blog/getArticles', async (_, {rejectWithValue}) => {
	try {
		const result = await apiClient.blog.getArticles()
		//@ts-ignore
		return result.data
	}
	catch(e: any) {
		return rejectWithValue(e.message)
	}
})

export const getArticle = createAsyncThunk('blog/getArticle', async (id: number, {rejectWithValue}) => {
	try {
		const result = await apiClient.blog.getArticle(id)
		//@ts-ignore
		return result.data
	}
	catch(e: any) {
		return rejectWithValue(e.message)
	}
})
export const createArticle = createAsyncThunk('blog/createArticle', async (data: {data: TArticle}, {rejectWithValue}) => {
	try {
		await apiClient.blog.createArticle(data)
	}catch(e: any) {
		return rejectWithValue(e.message)
	}
})
export const updateArticle = createAsyncThunk('blog/updateArticle', async (data: TArticle, {rejectWithValue}) => {
try {
	await apiClient.blog.updateArticle(data)
}
catch(e: any) {
	return rejectWithValue(e.message)
}
})
export const deleteArticle = createAsyncThunk('blog/DeleteArticle', async (id: number, {rejectWithValue}) => {
	try {
		await apiClient.blog.deleteArticle(id)
	}
	catch (e: any) {
		return rejectWithValue(e.message)
	}
})

export const blogSlice = createSlice({
	initialState,
	name: 'blog',
	reducers: {
		clearResult: (state) => {
			state.blogResult.type = ''
			state.blogResult.message = ''
		},
		clearArticle: (state) => {
			state.article = null
		},
		clearArticles: (state) => {
			state.articles = null
		}
	},
	extraReducers: builder => {
		builder.addCase(getArticles.fulfilled, (state, action) => {
			state.articles = action.payload.data
			state.blogResult.type = 'success'
			state.blogResult.message = 'Articles received Success'
			state.isLoading = false
		})
		builder.addCase(getArticles.rejected, (state, action) => {
			state.blogResult.type = 'error'
			//@ts-ignore
			state.blogResult.message = action.payload
			state.isLoading = true
		})
		builder.addCase(getArticle.fulfilled, (state, action) => {
			state.article = action.payload.data
			state.blogResult.type = 'success'
			state.blogResult.message = 'Article received Success'
		})
		builder.addCase(getArticle.rejected, (state, action) => {
			state.blogResult.type = 'error'
			//@ts-ignore
			state.blogResult.message = action.payload
		})
		builder.addCase(createArticle.fulfilled, (state) => {
			state.isLoading = true
			state.blogResult.type = 'success'
			state.blogResult.message = 'Article created success'
		})
		builder.addCase(createArticle.rejected, (state, action) => {
			state.blogResult.type = 'error'
			//@ts-ignore
			state.blogResult.message = action.payload
		})
		builder.addCase(updateArticle.fulfilled, (state) => {
			state.isLoading = true
			state.blogResult.type = 'success'
			state.blogResult.message = 'Article update success'
		})
		builder.addCase(updateArticle.rejected, (state, action) => {
			state.blogResult.type = 'error'
			//@ts-ignore
			state.blogResult.message = action.payload
		})
		builder.addCase(deleteArticle.fulfilled, (state) => {
			state.blogResult.type = 'success'
			state.blogResult.message = 'Article delete success'
		})
		builder.addCase(deleteArticle.rejected, (state, action) => {
			state.blogResult.type = 'error'
			//@ts-ignore
			state.blogResult.message = action.payload
		})
	}
})

export const {clearResult, clearArticle, clearArticles} = blogSlice.actions