import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

export type TToast = {
  type?: 'success' | 'error'
  content?: string
}

const initialState: TToast = {}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state: TToast, action: PayloadAction<TToast>) => {
      return { ...state, ...action.payload }
    },
    deleteToast: () => {
      return {}
    },
  },
})

export const { addToast, deleteToast } = toastSlice.actions
