import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { StyledBreadcrumbs } from './styles';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  return (
    <StyledBreadcrumbs>
      <Breadcrumb.Item>
        <Link to={'/'}>Home</Link>
      </Breadcrumb.Item>
      {pathnames.map((name, index) => (
        <span key={name}>
          <Breadcrumb.Item>
            <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>
              {name}
            </Link>
          </Breadcrumb.Item>
        </span>
      ))}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
