import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store';
import {
  ProfessionsWrapper,
  StyledAddButton,
  StyledCloseButton,
  StyledListItem,
  StyledListWrapper
} from './styles';
import { v4 as uuidv4 } from 'uuid';
import {
  deleteProf,
  getProfessions,
  setCurrentProffesion,
  setIsShow
} from '../../entities/professions/model/slice';
import { useDispatch } from 'react-redux';
import AddVacancy from '../../components/AddVacancy/AddVacancy';
import { useEffect, useState } from 'react';
import { navPath } from '../../functions';
import AddCategory from '../../components/AddCategory/AddCategory';

export const Professions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { professions } = useAppSelector(state => state.professions);
  const isAthorized = useAppSelector(state => state.auth);
  const [show, setShow] = useState<boolean>(false);
  const [showAddCategory, setShowAddCategory] = useState<boolean>(false);

  // const test = (arr: any, index: any) => {
  //   const newArray = arr.filter(
  //     (item: any, index2: number) => index2 !== index
  //   );
  //   dispatch(deleteProf(newArray));
  // };

  useEffect(() => {
    if (show || showAddCategory) {
      dispatch(setIsShow(true));
    } else {
      dispatch(setIsShow(false));
    }
  }, [show, showAddCategory]);
  useEffect(() => {
    //@ts-ignore
    dispatch(getProfessions());
  }, []);
  return (
    <ProfessionsWrapper>
      <StyledAddButton onClick={() => setShow(true)}>
        Add New Vacancy
      </StyledAddButton>
      <StyledAddButton onClick={() => setShowAddCategory(true)}>
        Add New Category
      </StyledAddButton>
      <StyledListWrapper>
        {professions?.map(item => (
          <StyledListItem key={uuidv4()}>
            <Link
              to={item.slug}
              onClick={e => {
                dispatch(setCurrentProffesion(item));
              }}
            >
              {item.name}
            </Link>
            {/* <StyledCloseButton onClick={() => test(professions, index)} /> */}
          </StyledListItem>
        ))}
      </StyledListWrapper>
      {show && (
        <AddVacancy
          professionsToAdd={professions}
          isCurrentProfesion={false}
          currentProfession={professions}
          setShow={setShow}
          show={show}
        />
      )}
      {showAddCategory && <AddCategory setShow={setShowAddCategory} />}
    </ProfessionsWrapper>
  );
};
export default Professions;
