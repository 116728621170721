import { Form as AntForm, Input } from 'antd';
import { StyledAddPostPage, StyledButton, StyledButtons, StyledFields } from './styles';
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import UploadImageArea from '../UploadArea/UploadImageArea'
import NoImageIcon from '../UploadArea/NoImageIcon'
import { StyledTitle } from '../AddVacancy/styles'
import CKEditorComponent from '../CKEditor/CKEditor'
import { useAppDispatch } from '../../store';
import { createArticle } from '../../entities/blog/model/slice'
type Props = {
  setAddShow: Dispatch<SetStateAction<boolean>>,
  addShow: boolean,
}
type FormData = {
  title: string,
  slug: string,
  keywords: string
}
const AddNewPost = ({setAddShow, addShow}: Props) => {
  const [preview, setPreview] = useState<File | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string>('')
	const [description, setDescription] = useState('')
	const [shortDescription, setShortDescription] = useState('')
	const dispatch = useAppDispatch()
  const handleFinish = (data: FormData) => {
    //eslint-disable-next-line
		const formData = new FormData
		formData.append('title', data.title)
		formData.append('slug', data.slug)
		formData.append('content', description)
		formData.append('short_description', shortDescription)
		preview && formData.append('image', preview)
		console.log(data.keywords.split(','));
		formData.append('keywords', data.keywords)
		//@ts-ignore
    dispatch(createArticle(formData))
    setAddShow(!addShow)
  };
	const handleLoadFile = (e: File | null): void => {
    setPreview(e)
    if (!e) {
      setPreview(null)
      setPreviewUrl('')
    }
  }
	useEffect(() => {
    if (preview) {
      setPreviewUrl(URL.createObjectURL(preview))
    }
  }, [preview])
  return (
    <>
      <StyledAddPostPage>
        <AntForm
          name='addNewPost'
          className='postForm'
          layout='vertical'
          onFinish={handleFinish}
					style={{padding: '0 15px'}}
        >
          <StyledButtons>
            <StyledButton onClick={() =>setAddShow(!addShow)}>Cancel</StyledButton>
            <StyledButton type='submit'>Save</StyledButton>
          </StyledButtons>
					<StyledFields>
          <AntForm.Item label={'Title'} name='title' hasFeedback>
            <Input placeholder={'Title'} />
          </AntForm.Item>
          <AntForm.Item label={'Slug'} name='slug' hasFeedback>
            <Input placeholder={'slug'} />
          </AntForm.Item>
					</StyledFields>
					<AntForm.Item label={'Keywords'} name='keywords' hasFeedback>
            <Input placeholder={'Keywords'} />
          </AntForm.Item>
					<AntForm.Item
            label={'Image'}
            // name="banner"
            // rules={getValidation(t, 'title')}
          >
            <UploadImageArea
              image={<NoImageIcon />}
              title={'image'}
              handleUpload={handleLoadFile}
              filePreview={previewUrl}
            />
          </AntForm.Item>
        </AntForm>
				<StyledTitle>Description</StyledTitle>
      <CKEditorComponent value={description} setContent={setDescription} />
      <StyledTitle>Short Description</StyledTitle>
      <CKEditorComponent
        value={shortDescription}  
        setContent={setShortDescription}
      />
      </StyledAddPostPage>
    </>
  );
};

export default AddNewPost;
