import apiClient from '../../../application/api'

import {
  type PayloadAction,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { addToast } from '../../../entities/toast/model/slice'
import { ILogInData } from '../../../components/auth/LogInForm/LogInForm'
import { clearUser, fetchUser } from '../../../entities/user/model/slice'
import { RootState } from '../../../store'
// import { IFirstStepData } from '../SignUp/ui/SignUpForm/FirstStep/FirstStep'
// import { ISecondStepData } from '../SignUp/ui/SignUpForm/SecondStep/SecondStep'

type AuthSliceState = {
  isAuthorized: boolean
}

// export interface IFormDataSignUp extends IFirstStepData, ISecondStepData {
//   isBonusesConfirmed: boolean
//   locale: string
//   role?: string
//   isTested?: string
// }

// export interface ISignUpPayloadData {
//   signUpData: IFormDataSignUp
//   t: TType
// }
// export const signUpUser = createAsyncThunk<void, ISignUpPayloadData>(
//   'auth/signUp',
//   async ({ t, signUpData }, { dispatch, rejectWithValue }) => {
//     try {
//       await apiClient.authorizations.signUp(signUpData)
//       dispatch(fetchUser())
//       dispatch(hideModalWithRedirect())
//     } catch (error) {
//       //@ts-ignore-next-line
//       if (error.message) {
//         //@ts-ignore-next-line
//         dispatch(addToast({ type: 'error', content: t(error.message) }))
//       } else {
//         dispatch(addToast({ type: 'error', content: t('somethingWereWrong') }))
//       }
//       return rejectWithValue('unauthorized')
//     }
//   }
// )
// export interface IFormDataSignIn extends IFirstStepData, ISecondStepData {
//   isBonusesConfirmed: boolean
//   locale: string
// }

export interface ISignInPayloadData {
  signInData: ILogInData
}
export const signInUser = createAsyncThunk<void, ISignInPayloadData>(
  'auth/signIn',
  async ({ signInData }, { dispatch, rejectWithValue }) => {
    try {
      await apiClient.authorizations.signIn(signInData)
      dispatch(fetchUser())
      // dispatch(hideModalWithRedirect())
    } catch (error) {

      //@ts-ignore-next-line
      if (error.message) {
        //@ts-ignore-next-line
        dispatch(addToast({ type: 'error', content: error.message }))
      } else {
        dispatch(addToast({ type: 'error', content:'somethingWereWrong' }))
      }
      return rejectWithValue('unauthorized')
    }
  }
)
export const signOutUser = createAsyncThunk(
  'auth/signOut',
  async (_, { dispatch }) => {
    try {
      await apiClient.authorizations.signOut()
      dispatch(clearUser())
    } catch (error) {
      dispatch(addToast({ type: 'error', content: String(error) })) // Dispatch action to another slice
    }
  }
)

const initialState: AuthSliceState = {
  isAuthorized: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthorized: (
      state: AuthSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.isAuthorized = action.payload
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(signUpUser.fulfilled, (state) => {
    //   state.isAuthorized = true
    // })
    builder.addCase(signInUser.fulfilled, (state) => {
      state.isAuthorized = true
    })
    builder.addCase(signOutUser.fulfilled, (state) => {
      state.isAuthorized = false
    })
  },
})

export const selectIsAuthorized = (state: RootState) =>
  state[authSlice.name].isAuthorized

export const { setIsAuthorized } = authSlice.actions
