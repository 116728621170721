import { Form as AntForm, Input } from 'antd';
import { useEffect, useState } from 'react';
import { deleteArticle, updateArticle } from '../../entities/blog/model/slice';
import { useAppDispatch } from '../../store';
import {
  StyledAddPostPage,
  StyledButton,
  StyledButtons,
  StyledFields
} from '../AddNewPost/styles';
import { StyledTitle } from '../AddVacancy/styles';
import CKEditorComponent from '../CKEditor/CKEditor';
import NoImageIcon from '../UploadArea/NoImageIcon';
import UploadImageArea from '../UploadArea/UploadImageArea';
import { useNavigate } from 'react-router-dom';
const EditPost = ({
  cancel,
  title,
  slug,
  id,	
  postDescription,
  postShortDescription,
  imageUrl,
	keywords
}: {
  cancel: () => void;
  title: string;
  slug: string;
  id: string;
  postDescription: string;
  postShortDescription: string;
  imageUrl: string;
	keywords: string
}) => {
  const [preview, setPreview] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
	const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const handleFinish = (data: any) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('slug', data.slug);
    formData.append('content', description);
    formData.append('short_description', shortDescription);
    formData.append('id', id);
    preview && formData.append('image', preview);
    formData.append('keywords', data.keywords.split(','));
		const dataToSend = {
			dataToSend: formData,
			id: id
		}
    //@ts-ignore
    dispatch(updateArticle(dataToSend));
		navigate(-1)
  };
  const handleLoadFile = (e: File | null): void => {
    setPreview(e);
		
    if (!e) {
      setPreview(null);
      setPreviewUrl('');
    }
  };
  useEffect(() => {
    setDescription(postDescription);
    setShortDescription(postShortDescription);

  }, [id,postDescription, postShortDescription]);
  useEffect(() => {
    if (preview) {
      setPreviewUrl(URL.createObjectURL(preview));
    } else {
      setPreviewUrl(imageUrl);
    }
  }, [preview, imageUrl]);
	const handleDelete = () => {
		dispatch(deleteArticle(+id))
		navigate(-1)
	}
  return (
    <>
      <StyledAddPostPage>
        <AntForm
          name='addNewPost'
          className='postForm'
          layout='vertical'
          onFinish={handleFinish}
          initialValues={{
            title: title,
            slug: slug,
            keywords: keywords.replace(/\\+/g, '').replace(/"/g, '')
          }}
          style={{ padding: '0 15px' }}
        >
          <StyledButtons>
            <StyledButton onClick={cancel} type='button'>Cancel</StyledButton>
            <StyledButton type='submit'>Save</StyledButton>
            <StyledButton type='button' onClick={handleDelete}>Delete</StyledButton>
          </StyledButtons>
          <StyledFields>
            <AntForm.Item label={'Title'} name='title' hasFeedback>
              <Input placeholder={'Title'} />
            </AntForm.Item>
            <AntForm.Item label={'Slug'} name='slug' hasFeedback>
              <Input placeholder={'slug'} />
            </AntForm.Item>
          </StyledFields>
          <AntForm.Item label={'Keywords'} name='keywords' hasFeedback>
            <Input placeholder={'Keywords'} />
          </AntForm.Item>
          <AntForm.Item
            label={'Image'}
            // name="banner"
            // rules={getValidation(t, 'title')}
          >
            <UploadImageArea
              image={<NoImageIcon />}
              title={'image'}
              handleUpload={handleLoadFile}
              filePreview={previewUrl}
            />
          </AntForm.Item>
        </AntForm>
        <StyledTitle>Description</StyledTitle>
        <CKEditorComponent value={description} setContent={setDescription} />
        <StyledTitle>Short Description</StyledTitle>
        <CKEditorComponent
          value={shortDescription}
          setContent={setShortDescription}
        />
      </StyledAddPostPage>
    </>
  );
};

export default EditPost;
