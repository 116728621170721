import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AddNewPost from '../../components/AddNewPost/AddNewPost';
import { clearArticles, getArticles } from '../../entities/blog/model/slice';
import { backend } from '../../functions';
import { useAppDispatch, useAppSelector } from '../../store';
import { StyledAddButton } from '../Profassions/styles';
import {
  StyledBlogArticle,
  StyledImage,
  StyledImageBlock,
  StyledLink,
  StyledPageBlog,
  StyledSpiner
} from './styles';

const BlogPage = () => {
  const dispatch = useAppDispatch();
  const { articles, isLoading } = useAppSelector(state => state.blog);
  const [addShow, setAddShow] = useState<boolean>(false);
  useEffect(() => {
    dispatch(getArticles());
    return () => {
      dispatch(clearArticles());
    };
  }, [isLoading, dispatch]);
  return (
    <>
      {isLoading ? (
        <StyledSpiner size='large' />
      ) : (
        <>
          {addShow ? (
            <AddNewPost setAddShow={setAddShow} addShow={addShow} />
          ) : (
            <>
              <StyledAddButton onClick={() => setAddShow(true)}>
                Add New Post
              </StyledAddButton>
              <StyledPageBlog>
                {articles?.map(item => (
                  <StyledBlogArticle key={uuidv4()}>
                    <StyledImageBlock to={JSON.stringify(item.id)}>
                      <StyledImage
                        src={`${backend}${item.image}`}
                        alt='Article image'
                      />
                    </StyledImageBlock>
                    <StyledLink to={JSON.stringify(item.id)}>
                      {item.title}
                    </StyledLink>
                  </StyledBlogArticle>
                ))}
              </StyledPageBlog>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BlogPage;
