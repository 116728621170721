import { useEffect } from 'react';
import { getVacancy } from '../../entities/vacancies/model/slice';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store'
import { clear } from '../../entities/vacancies/model/slice';
import VacancyForm from './VacancyForm'
const Vacancy = () => {
	const {vacancy} = useAppSelector(state => state.vacancies)

	
	const dispatch = useAppDispatch()
	const {id} = useParams()
	
	useEffect(() => {
		dispatch(getVacancy(id))
		return () => {
			dispatch(clear())
		}
	}, [id, dispatch])
	if (!vacancy) {
    return <div>Loading...</div>; // or some other loading indicator
  }
	const {description, is_active, keywords, name, short_description, slug, category_id} = vacancy
	//@ts-ignore
	const parseKeywords = JSON.parse(keywords)
	
	return ( <>
	<VacancyForm name={name} keywords={parseKeywords} description={description} shortDescription={short_description} is_active={is_active} id={+id!} categoryId={category_id} slug={slug}/>
	</> );
}
 
export default Vacancy;