import Cookies from 'js-cookie';
import { TAuthHeaders } from '../types';
import axios from 'axios';

export const authHeadersKey = 'token';
export const shouldExpireTokenKey = 'should_expire';

export const setAuthCookies = (accessToken: string): void => {
  const shouldExpireToken = Cookies.get(shouldExpireTokenKey);
  if (shouldExpireToken) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    Cookies.set(authHeadersKey, accessToken, {
      secure: true,
      expires: expirationDate
    });
  } else {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    Cookies.set(authHeadersKey, accessToken, {
      secure: true,
      expires: expirationDate
    });
  }
};

export const deleteAuthCookies = (): void => {
  Cookies.remove(authHeadersKey);
};

export const setAuthHeaders = (accessToken: string): void => {
  axios.defaults.headers.common.authorization = accessToken;
};

export const updateAuthHeaders = (): void => {
  const accessToken = Cookies.get(authHeadersKey);
  if (accessToken) setAuthHeaders(`Bearer ${accessToken}`);
};

export const deleteAuthHeaders = (): void => {
  delete axios.defaults.headers.common.authorization;
};

export const updateHeaders = (headers: TAuthHeaders): void => {
  setAuthHeaders(headers.token);
  setAuthCookies(headers.token);
};

export const removeHeaders = (): void => {
  deleteAuthCookies();
  deleteAuthHeaders();
};
