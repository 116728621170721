import { LogInForm } from './components/auth/LogInForm/LogInForm';
import styled from 'styled-components';
import './index.css';
import { useAppSelector } from './store';
import { StyledMainLogInForm, StyledMainPageLoginForm } from './styles';
import Layout from './Laoyout'

export const StyledMainPage = styled.div({
  maxWidth: '100%',
  width: '100%',
  background: '#060606',
  minHeight: '100vh'
});
function App() {
  const { isAuthorized } = useAppSelector(state => state.auth);
  const { isShow } = useAppSelector(state => state.professions);
  return (
    <div className={`App ${isShow ? 'show' : ''}`}>
      {!isAuthorized ? (
        <StyledMainPage>
          <StyledMainPageLoginForm>
            <StyledMainLogInForm>
              <LogInForm />
            </StyledMainLogInForm>
          </StyledMainPageLoginForm>
        </StyledMainPage>
      ) : (
        <StyledMainPage>
          <Layout />
        </StyledMainPage>
      )}
    </div>
  );
}

export default App;
