import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../../application/api/';

type Professions = {
  name: string;
  slug: string;
};
export type currentProfession = {
  id: number;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
};
type State = {
  professions: currentProfession[] | null;
  currentProfession: currentProfession | null;
  isLoading: boolean;
  isShow: boolean;
  data?: Professions[];
};
const initialState: State = {
  professions: [],
  currentProfession: null,
  isLoading: false,
  isShow: false
};

export const getProfessions = createAsyncThunk<State>(
  'professions/getProfessions',
  async () => {
    try {
      const result = await apiClient.professions.getProfessions();
      //@ts-ignore
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const createProfession = createAsyncThunk(
  'professions/createProfesion',
  async (data: any) => {

    try {
      await apiClient.professions.createProfession(data);
    } catch (e) {
      console.log(e);
    }
  }
);
export const professionsSlice = createSlice({
  name: 'professions',
  initialState,
  reducers: {
    setIsShow: (state, action) => {
      state.isShow = action.payload;
    },
    setCurrentProffesion: (state, action) => {
      state.currentProfession = action.payload;
    },
    deleteProf: (state, action) => {
      state.professions = action.payload;
    },
    clear: state => {
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(getProfessions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getProfessions.fulfilled, (state, action) => {
      //@ts-ignore
      state.professions = action.payload.data;
      state.isLoading = false;
    });
  }
});

export const { clear, setCurrentProffesion, deleteProf, setIsShow } =
  professionsSlice.actions;
