export const brekePointsDevises = {
  mobileSm: 360,
  mobileMd: 414,
  mobileLg: 480,
  tabletSm: 730,
  tablet: 834,
  tabletLg: 1006,
  desctop: 1440,
}

export const stylesConfig = {
  maxSiteWidth: '1980px',
  colors: {
    color1: '#75FBFA', //blue
    color2: '#071826', //dark-blue
    color3: '#FAD849', //yellow
    color4: '#F9F4F4', //white
    color5: '#050D13', //darkest-blue
    color6: '#959292', //gray
    color7: '#E51D63', //red
    color8: '#0EDB78', //green
    color9: 'rgba(5, 13, 19, 0.8)', //black-opacity
    color10: '#FFFFFF', //white-clear
    color11: '#B6A5A5', //light-grey
    color12: '#151515', //black
    color13: '#A49B9B', //medium-grey
    color14: '#7A641B', //dark-yellow
    color15: '#0B0A16', //light-black
    color16: 'rgba(7, 24, 38, 0.3)',
  },
  height: {
    // Buttons
    buttonLarge: '48px',
    buttonMedium: '32px',
    buttonSmall: '28px',
    // Header
    header: '80px',
    //Toast
    toast: '72px',
  },
  width: {
    toast: '265px',
    menu: '216px',
    contentContainer: '800px',
  },
  background: {
    baseWithOpasity: 'rgba(7, 24, 38, 0.3)',
    cards: 'rgba(0, 0, 0, 0.03)',
    darkWithOpacity: 'rgba(5, 13, 19, 0.8)',
  },
  boxShadow: {
    base: '0px 4px 4px rgba(6, 14, 19, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.1)',
    cards:
      '0px 0px 20px rgba(0, 224, 255, 0.1), 0px 4px 4px rgba(6, 14, 19, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.1);',
    filter:
      '0px 4px 20px rgba(0, 0, 0, 0.32), inset 0px 1px 1px rgba(255, 255, 255, 0.1)',
  },
  textShadow: {
    base: '0px 0px 2px #000000',
  },
  backgroundFilter: {
    base: 'blur(10px)',
  },
  // Google fonts only!!!
  fontFamily: {
    base: 'Oswald',
    poppins: 'Poppins',
  },
  fontWeight: {
    thin: 300,
    base: 400,
    medium: 500,
    bold: 600,
  },
  padding: {
    base: '16px',
    // Buttons
    buttonSmallPadding: '4px 0',
    buttonPadding: '8px 32px',
    // Header
    headerDesctopX: '88px',
    headerDesctopY: '8px',
    headerMobile: '12px',
    blockTitlePadding: '64px',
    blockTitleTabletPadding: '32px',
    //MainGames
    mainGames: '48px',
    mainGamesTablet: '32px',
    // Content container
    contentContainer: '10px',
    contentContainerTablet: '30px',
    // Navigation
    innerNavItem: '8px 15px',
  },
  margin: {
    base: '16px',
    // Header
    headerDesctop: '8px',
    headerMobile: '12px',
    landswitcherText: '8px',
    burgerRight: '32px',
    burgerRightMobile: '12px',
    formItem: '24px',
    titleBottom: '24px',
    pagination: '24px',
    // Info content
    h1Magrin: '24px',
    pMagrin: '20px',
  },
  fontSize: {
    xxSmall: '10px',
    xSmall: '12px',
    small: '14px',
    medium: '16px',
    base: '20px',
    large: '24px',
    extraLarge: '32px',
    superLarge: '48px',
    largest: '148px',
  },
  lineHeight: {
    medium: '20px',
    base: '32px',
    large: '48px',
    extraLarge: '56px',
    largest: '104px',
    // Info content
    pLineHeight: '24px',
  },
  borderRadius: {
    base: '8px',
    innerNavItem: '12px',
  },
  brakePoints: {
    mobileSm: brekePointsDevises.mobileSm + 'px',
    mobileMd: brekePointsDevises.mobileMd + 'px',
    mobileLg: brekePointsDevises.mobileLg + 'px',
    tabletSm: brekePointsDevises.tabletSm + 'px',
    tablet: brekePointsDevises.tablet + 'px',
    tabletLg: brekePointsDevises.tabletLg + 'px',
    desctop: brekePointsDevises.desctop + 'px',
  },
}
