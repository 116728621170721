import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Form as AntForm, Input } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';

import Cookies from 'js-cookie';
import { stylesConfig } from '../../../application/lib/stylesConfig';
import { signInUser } from '../../../entities/auth/model/slice';
import { useAppDispatch } from '../../../store';
import { shouldExpireTokenKey } from '../../../application/api/headers/headers';
import { StyledAddButton } from '../../../pages/Profassions/styles';
import { useNavigate } from 'react-router-dom';
export const StyledLogInForm = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${stylesConfig.brakePoints.desctop}) {
    flex-direction: row;
  }
  .ant-input-affix-wrapper {
    padding: 9px 12px;
  }
`;
const StyledText = styled.span`
  line-height: ${stylesConfig.lineHeight.medium};
  font-size: ${stylesConfig.fontSize.medium};
  cursor: default;
`;
export const StyledPrimaryText = styled(StyledText)`
  color: ${stylesConfig.colors.color3};
  font-weight: ${stylesConfig.fontWeight.base};
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  @media (min-width: ${stylesConfig.brakePoints.desctop}) {
    width: 620px;
  }
`;

export const StyledFormWrap = styled.div`
  background: ${stylesConfig.colors.color5};
  padding: ${stylesConfig.padding.base};
  @media (min-width: ${stylesConfig.brakePoints.tablet}) {
    min-width: 573px;
    box-sizing: border-box;
  }
  @media (min-width: ${stylesConfig.brakePoints.desctop}) {
    min-width: 416px;
  }
`;
export const StyledTitleText = styled.div`
  font-family: ${stylesConfig.fontFamily.base};
  font-weight: ${stylesConfig.fontWeight.base};
  font-size: ${stylesConfig.fontSize.extraLarge};
  line-height: ${stylesConfig.lineHeight.large};
  color: ${stylesConfig.colors.color3};
  margin-bottom: ${stylesConfig.margin.base};
  cursor: default;
`;
const StyledCheckboxLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export interface ILogInData {
  email: string;
  password: string;
  gReCaptchaToken: string;
  remember?: boolean;
}

export const LogInForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onFinish = useCallback((dataToSend: ILogInData) => {
    if (!dataToSend.remember) {
      Cookies.set(shouldExpireTokenKey, 'YES', { secure: true });
    } else {
      Cookies.remove(shouldExpireTokenKey);
    }

    dispatch(
      signInUser({
        signInData: { ...dataToSend }
      })
    );
    navigate('/');
  }, []);
  // const handleShowForgotPassword = (): void => {
  //   // do nothing
  // }
  return (
    <StyledLogInForm>
      <StyledFormWrap>
        <StyledTitleText>{'Login'}</StyledTitleText>
        <AntForm
          name='normal_login'
          className='login-form'
          layout='vertical'
          requiredMark={false}
          initialValues={{ remember: false }}
          onFinish={onFinish}
        >
          <AntForm.Item label={'Email'} name='email' hasFeedback>
            <Input placeholder='Email' />
          </AntForm.Item>

          <AntForm.Item
            label={'Password'}
            name='password'
            hasFeedback
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              placeholder='Password'
              iconRender={visible =>
                visible ? <EyeFilled /> : <EyeInvisibleFilled />
              }
            />
          </AntForm.Item>
          {/* <StyledCheckboxLine>
            <AntForm.Item>
              <AntForm.Item name='remember' valuePropName='checked' noStyle>
                <Checkbox>{t('rememberMe')}</Checkbox>
              </AntForm.Item>
            </AntForm.Item>
          </StyledCheckboxLine> */}
          <AntForm.Item>
            <StyledAddButton>{'Login'}</StyledAddButton>
          </AntForm.Item>
        </AntForm>
      </StyledFormWrap>
    </StyledLogInForm>
  );
};
