import React from 'react'
import styled from 'styled-components'

const StyledNoImageIcon = styled.img`
  width: 28px;
  height: 28px;
`

const NoImageIcon: React.FC = () => {
  return <StyledNoImageIcon src={'/noImageIcon.svg'} />
}

export default NoImageIcon
