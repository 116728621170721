import { Link } from 'react-router-dom'
import styled from 'styled-components';

export const StyledPagePortfolio = styled.div({
	width: '100%',

})

export const StyledProjectsWrapper = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'column',
	gap: '8px',
	width: '30%'
})

export const STyledProjectImage = styled.div({
	maxWidth: '400px'
})
export const StyledLink = styled(Link)({
	display: 'block',
	transition: 'all .3s',
	'&:hover' : {
		color: '#fff'
	}
})

export const StyledImage = styled.img({
	width: '100%',
	height: 'auto',
	objectFit: 'contain'
})