import { message } from 'antd';
import { useEffect } from 'react';
import { deleteResult } from '../../entities/vacancies/model/slice';
import { useAppDispatch, useAppSelector } from '../../store';
import { clearResult } from '../../entities/blog/model/slice'
export const showToast = (error: string, message: string) => {
  return (
    <>
      <div>{message}</div>
      <div>{error}</div>
    </>
  );
};
const Toast = () => {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { result } = useAppSelector(state => state.vacancies);
  const {blogResult} = useAppSelector(state => state.blog)
  const {ProjectResult} = useAppSelector(state => state.portfolio)
  useEffect(() => {
    result.message !== '' &&
      messageApi.open({
        //@ts-ignore
        type: result.type,
        content: result.message
      });
    dispatch(deleteResult());
    blogResult.message !== '' &&
    messageApi.open({
      //@ts-ignore
      type: blogResult.type,
      content: blogResult.message
    })
    dispatch(clearResult())
  }, [result, blogResult]);
  return <>{contextHolder}</>;
};

export default Toast;
