import styled from 'styled-components';
export const VacancyCard = styled.div({
  color: '#fff',
  width: '25%',
  background: '#1f1f1f',
  padding: '30px',
  boxSizing: 'content-box',
  borderRadius: '12px',
  h1: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    margin: '0 0 15px 0'
  },
  h2: {
    fontSize: '.8rem',
    fontWeight: 'bold',
    margin: '0 0 5px 0'
  },
  ul: {
    margin: '0 0 5px 0'
  },
  li: {
    margin: '0 0 5px 0',
    listStyleType: '"-"',
    listStylePosition: 'inside'
  },
  p: {
    margin: '0 0 5px 0'
  }
});

export const VacanciesWrapper = styled.ul({
  display: 'block'
});

export const StyledShortDescription = styled.div({
  maxHeight: '150px',
  height: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, .3)',
    borderRadius: '10px'
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent'
  }
});
export const ProfessionWrapper = styled.div({
  maxWidth: '1920px',
  width: '100%',
  padding: '0 15px'
});
export const StyledVacancyTitle = styled.h2({
  fontSize: '1.5rem',
  color: '#fff',
  fontWeight: 'normal'
});
