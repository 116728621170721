import styled from 'styled-components';
import { StyledAddButton } from '../../pages/Profassions/styles'
import CKEditorComponent from '../CKEditor/CKEditor'

export const StyledAddPostPage = styled.div({
	position: 'relative',
	'.ant-form-item-control' : {
		maxWidth: '500px'
	},
	label: {
		color: '#fff !important'
	},
})
export const StyledButtons = styled.div({
	display: 'flex',
	gap: '16px',
	justifyContent: 'flex-end'
})

export const StyledButton = styled(StyledAddButton)({
	margin: '0'
})
export const StyledFields = styled.div({
	display: 'flex',
	gap: '16px',
	'.ant-form-item': {
		//@ts-ignore

		width: '40%'
	},
})

