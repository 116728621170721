import { CKEditor } from '@ckeditor/ckeditor5-react';
import { stylesConfig } from '../../application/lib/stylesConfig';
import { styled } from 'styled-components';
import Editor from 'ckeditor-custom-build-by-rujuy';

const StyledWysiwyg = styled.div`
  figure {
    width: 100%;
    table {
      width: 100%;
    }
    table td {
      padding: 24px !important;
      flex-direction: column;
      flex-grow: 1;
    }
    table td[data-row='1'] {
      color: ${stylesConfig.colors.color4};
    }
    margin: 0;
  }
  ul {
    li {
      list-style: disc;
      list-style-position: inside;
    }
  }
  ol {
    li {
      list-style: auto;
      list-style-position: inside;
    }
  }
  .ck.ck-editor {
    border-radius: 8px;
    padding: 0 15px;
    margin: 0 0 15px 0;
  }
  .ck-sticky-panel__content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ck .ck-toolbar {
    border: none;
    background: rgba(7, 24, 38, 0.3);
  }
  .ck .ck-conten {
    background-color: rgba(7, 24, 38, 1);
    color: white;
    border-radius: 13px;
    background: rgba(7, 24, 38, 0.3);
  }
  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border: none;
    background-color: rgba(7, 24, 38, 1);
    color: white;
    background: rgba(7, 24, 38, 0.3);
  }
  .ck.ck-list {
    border: none;
    background-color: rgba(7, 24, 38, 1);
    color: white;
    .ck.ck-list__item .ck-button__label {
      color: #fff !important;
    }
  }
  .ck.ck-list__item
    .ck-button.ck-on:focus:not(.ck-switchbutton):not(.ck-disabled) {
    background-color: transparent;
  }
  .ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
    background-color: rgba(9, 36, 59, 0.3);
  }
  .ck.ck-button.ck-on:not(.ck-disabled):hover,
  .ck.ck-button.ck-on:not(.ck-disabled) {
    background: transparent;
  }
  .ck.ck-list__item .ck-button.ck-on {
    background: transparent;
    border: unset;
  }
  .ck.ck-dropdown__panel {
    border: unset;
  }
  .ck.ck-dropdown__panel {
    background-color: rgba(7, 24, 38, 1);
  }
  .ck.ck-editor__main > .ck-editor__editable {
    height: 60vh;
  }
  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-radius: 0;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
  }
  .ck-rounded-corners .ck.ck-dropdown__panel {
    border-radius: 13px;
  }
  .ck.ck-list__item
    .ck-button.ck-on:focus:not(.ck-switchbutton):not(.ck-disabled) {
    border-color: transparent;
  }
  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border: unset;
  }
  .ck.ck-icon.ck-icon_inherit-color :not([fill]) {
    fill: #fff;
  }
  .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
    color: #fff;
  }
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover {
    background-color: transparent;
  }
  .ck-powered-by {
    display: none;
  }
  .ck-editor__editable::-webkit-scrollbar-track {
    background: transparent;
  }
  .ck-editor__editable::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background: rgba(51, 60, 67, 0.2);
  }
  .ck-editor__editable:-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
//@ts-ignore
const CKEditorComponent = ({ value, setContent }) => {
  const colors = [
    '#75FBFA',
    '#071826',
    '#FAD849',
    '#F9F4F4',
    '#050D13',
    '#959292',
    '#E51D63',
    '#0EDB78',
    '#FFFFFF',
    '#B6A5A5',
    '#151515',
    '#A49B9B',
    '#7A641B',
    '#0B0A16'
  ];
  const editorConfiguration = {
    fontColor: {
      colors: colors
    },
    heading: {
      options: [
        { model: 'paragraph', view: 'p', title: 'Paragraph' },
        { model: 'heading2', view: 'h2', title: 'Heading 2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4' }
      ]
    }
  };

  return (
    <>
      <StyledWysiwyg>
        <CKEditor
          editor={Editor}
          //@ts-ignore-next-line
          config={editorConfiguration}
          data={value}
          onReady={editor => {}}
          onChange={(event, editor) => {
            //@ts-ignore-next-line
            const data = editor.getData();
            setContent(data);
            console.log(data);
            
          }}
        />
      </StyledWysiwyg>
    </>
  );
};

export default CKEditorComponent;
