import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import type { UploadProps } from 'antd'
import { useAppDispatch } from '../../store/'
const { Dragger } = Upload

const UploadDocDeleteBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 8px;
  &:hover svg {
    opacity: 75%;
  }
`

const StyledDragger = styled(Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0;
  }
  .ant-upload-drag .ant-upload-drag-container {
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const StyledUploadText = styled.span`
  color: #c8c4c4;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`
const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
`
const StyledImageWrap = styled.div`
  position: relative;
  width: fit-content;
  height: 204px;
  padding: 12px;
  border-radius: 12px;
  flex-grow: 1;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
`
interface Props {
  image: ReactNode
  title: string
  status?: string
  filePreview?: string
  handleUpload: (e: File | null) => void
}

export const UploadImageArea: React.FC<Props> = ({
  image,
  title,
  status,
  filePreview,
  handleUpload,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.png, .gif, .jpeg, .jpg, .webp',
    customRequest: async () => {
      return null
    },
    onChange(info) {
      if (status !== 'uploading') {
        // @ts-ignore-next-line
        handleUpload(info.file.originFileObj)
      }
      if (status === 'done') {
        console.log(`file uploaded successfully.`)
      } else if (status === 'error') {
      }
    },
    onDrop(e) {
      console.log('Dropped files', e)
      handleUpload(e.dataTransfer.files[0])
    },
    onDownload() {
      console.log('Download')
    },
  }

  return (
    <>
      {filePreview ? (
        <StyledImageWrap>
          <UploadDocDeleteBtn
            onClick={(): void => {
              handleUpload(null)
            }}
            title={t('remove')}
          >
            <DeleteOutlined />
          </UploadDocDeleteBtn>
          <StyledImage src={filePreview} alt="Uploaded preview" />
        </StyledImageWrap>
      ) : (
        <StyledDragger {...props}>
          {image}
          <StyledUploadText className="ant-upload-text">
            {title}
          </StyledUploadText>
        </StyledDragger>
      )}
    </>
  )
}

export default UploadImageArea
